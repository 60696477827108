<template>
  <div class="salesPromotion">
    <div class="cell">
      <div class="tab-head">
        <div
          @click="qihuan(0)"
          :style="{
            'border-color': index == 0 ? '#ee4d2d' : '#fff',
            color: index == 0 ? '#ee4d2d' : '#666',
          }"
        >
          我的店铺促销活动
        </div>
        <!-- <div
          @click="qihuan(1)"
          :style="{
            'border-color': index == 1 ? '#ee4d2d' : '#fff',
            color: index == 1 ? '#ee4d2d' : '#666',
          }"
        >
          促销中的商品
        </div> -->
      </div>
      <div class="tab-content" v-show="index == 0">
        <div style="padding: 20px 0; font-size: 16px; color: #b0b0b0">
          在此处查看您的促销活动可以更好地管理您的营销活动。促销活动包括：优惠券，折扣促销，套装优惠，加购组合促销，限时选购。
        </div>
        <div>
          <el-form
            style="
              display: flex;
              align-items: center;
              flex-wrap: wrap;
              margin-top: 20px;
              justify-content: space-between;
            "
            ref="form"
            :model="form"
            label-width="120px"
          >
            <el-form-item
              style="width: 45%; color: #b0b0b0"
              label="促销活动类型"
            >
              <el-select
                style="width: 100%"
                v-model="form.type"
                placeholder="请选择活动类型"
              >
                <el-option
                  v-for="(item, i) in typeOption"
                  :key="i"
                  :label="item"
                  :value="i"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item style="width: 45%" label="促销活动状态">
              <el-select
                style="width: 100%"
                v-model="form.status"
                placeholder="请选择活动状态"
              >
                <el-option
                  v-for="(item, i) in statusOption"
                  :key="i"
                  :label="item"
                  :value="i"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item style="width: 45%" label="促销活动名称">
              <el-input style="width: 100%" v-model="form.name"></el-input>
            </el-form-item>
            <el-form-item style="width: 45%" label="促销活动时期">
              <el-date-picker
                style="width: 100%"
                v-model="form.time"
                type="daterange"
                range-separator="-"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :default-time="['00:00:00', '11:59:59']"
                value-format="timestamp"
              >
              </el-date-picker>
            </el-form-item>
          </el-form>
        </div>
        <div>
          <el-button
            @click="handleSearch(1)"
            size="small"
            type="primary"
            style="min-width: 80px"
            >搜索</el-button
          >
          <el-button
            @click="handleSearch(2)"
            size="small"
            style="min-width: 80px"
            plain
            >重置</el-button
          >
        </div>
        <div class="list">
          <div class="list-head">
            <div style="width: 20%">促销活动名称</div>
            <div style="width: 20%">促销活动类型</div>
            <div style="width: 20%">商品</div>
            <div style="width: 15%">状态</div>
            <div style="width: 15%">时期</div>
            <div style="width: 10%">操作</div>
          </div>
          <div
            class="list-content"
            v-for="(item, index) in listData.list"
            :key="item.id"
          >
            <div style="width: 20%">
              {{ item.promotions_name ? item.promotions_name : "-" }}
            </div>
            <div style="width: 20%">
              {{
                item.promotions_type == 1
                  ? "优惠券"
                  : item.promotions_type == 2
                  ? "折扣活动"
                  : item.promotions_type == 3
                  ? "套装优惠"
                  : item.promotions_type == 4
                  ? "限时选购"
                  : ""
              }}
            </div>
            <div style="width: 20%">
              <el-image
                style="width: 40px; height: 40px"
                :src="item.cover_image"
                fit="fit"
              ></el-image>
            </div>
            <div style="width: 15%">
              <div class="tag" v-if="item.status_txt == '接下来的活动'">
                接下来的活动
              </div>
              <div class="tag1" v-if="item.status_txt == '进行中的活动'">
                进行中的活动
              </div>
              <div class="tag2" v-if="item.status_txt == '已过期'">已过期</div>
            </div>
            <div style="width: 15%; font-size: 14px">
              <div>{{ item.start_time | formatTime }} -</div>
              <div>{{ item.end_time | formatTime }}</div>
            </div>
            <div style="width: 10%">
              <div
                v-if="item.status_txt != '已过期'"
                style="
                  font-size: 13px;
                  color: #317adf;
                  cursor: pointer;
                  margin-bottom: 6px;
                "
                @click="toEdit('edit', item)"
              >
                编辑
              </div>
              <div
                v-else
                @click="toInfo('info', item)"
                style="
                  font-size: 13px;
                  color: #317adf;
                  margin-bottom: 6px;
                  cursor: pointer;
                "
              >
                详情
              </div>

              <div
                style="
                  font-size: 13px;
                  color: #317adf;
                  cursor: pointer;
                  margin-bottom: 6px;
                "
                @click="toCope('copy', item)"
              >
                复制
              </div>
              <div
                v-if="
                  item.status_txt != '接下来的活动' ||
                  item.promotions_type == 1 ||
                  item.promotions_type == 4
                "
                style="
                  font-size: 13px;
                  color: #317adf;
                  cursor: pointer;
                  margin-bottom: 6px;
                "
                @click="toData('data', item)"
              >
                {{
                  item.promotions_type == 1
                    ? "订单"
                    : item.promotions_type == 3
                    ? "订单&数据"
                    : "数据"
                }}
              </div>
              <div
                v-if="item.status_txt == '进行中的活动'"
                style="font-size: 13px; color: #317adf; cursor: pointer"
                @click="handleDel(item, 1, index)"
              >
                结束
              </div>
              <div
                v-if="item.status_txt == '接下来的活动'"
                style="font-size: 13px; color: #317adf; cursor: pointer"
                @click="handleDel(item, 2, index)"
              >
                删除
              </div>
            </div>
          </div>
        </div>
        <div style="display: flex; justify-content: right; padding: 20px">
          <el-pagination
            background
            layout="prev, pager, next"
            :total="listData.total"
            :page-size="page_size"
            :current-page="page"
            @current-change="change"
          >
          </el-pagination>
        </div>
      </div>
      <div class="tab-content" v-show="index == 1">
        <div style="padding: 20px 0; font-size: 16px; color: #b0b0b0">
          您可以通过商品名称/商品货号以搜索商品。
        </div>
        <div class="search">
          <span style="margin-right: 10px">搜索</span>
          <el-select
            size="medium"
            style="width: 200px"
            v-model="searchIndex"
            placeholder="请选择"
          >
            <el-option
              v-for="(item, i) in searchOption"
              :key="i"
              :label="item"
              :value="i"
            >
            </el-option> </el-select
          ><el-input
            size="medium"
            style="width: 300px"
            v-model="searchVal"
            placeholder="搜索内容"
          ></el-input>
          <el-button
            type="primary"
            size="medium"
            style="min-width: 80px; margin-left: 20px"
            >搜索</el-button
          >
        </div>

        <div class="list">
          <div class="list-head">
            <div style="width: 10%">商品名称</div>
            <div style="width: 10%">商品货号</div>
            <div style="width: 10%">规格</div>
            <div style="width: 10%">原价</div>
            <div style="width: 10%">促销价格</div>
            <div style="width: 10%">促销类型</div>
            <div style="width: 10%">促销名称</div>
            <div style="width: 10%">状态|促销时间</div>
          </div>
          <div class="list-content" v-for="item in 6" :key="item">
            <div style="width: 10%">创建新的折扣活动</div>
            <div style="width: 10%">创建新的折扣活动</div>
            <div style="width: 10%">创建新的折扣活动</div>
            <div style="width: 10%">我的折扣活动</div>
            <div style="width: 10%">
              <el-image
                style="width: 40px; height: 40px"
                :src="url"
                fit="fit"
              ></el-image>
            </div>
            <div style="width: 10%">
              <el-tag type="info">已过期</el-tag>
            </div>
            <div style="width: 10%; font-size: 14px">
              <div>2022-7-6 14:43 -</div>
              <div>2022-7-6 14:43</div>
            </div>
            <div style="width: 10%">
              <div
                style="
                  font-size: 13px;
                  color: #317adf;
                  margin-bottom: 6px;
                  cursor: pointer;
                "
              >
                详情
              </div>
              <div style="font-size: 13px; color: #317adf; cursor: pointer">
                复制
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  list,
  deleteCoupon,
  deleteDiscount,
  deleteSuit,
  deleteLimitPurchases,
} from "@/api/marketing.js";
export default {
  data() {
    return {
      index: 0,
      typeOption: [
        "所有",
        "优惠券",
        "我的折扣活动",
        "套装优惠",
        "商店的限时选购",
      ],
      statusOption: ["所有", "进行中的活动", "接下来的活动", "已过期"],
      searchOption: ["商品名称", "商品货号"],
      searchIndex: 0,
      searchVal: "",
      form: {
        type: 0,
        status: 0,
        name: "",
        time: [],
      },
      url: "",
      activityList: [],
      listData: [],
      page: 1,
      page_size: 10,
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      list({
        active_type: this.form.type,
        active_status: this.form.status,
        active_name: this.form.name,
        active_start_time: this.form.time[0] ? this.form.time[0] / 1000 : "",
        active_end_time: this.form.time[1] ? this.form.time[1] / 1000 : "",
        page_size: this.page_size,
        page: this.page,
      }).then((res) => {
        if (res.code == 1) {
          this.listData = res.data;
        }
      });
    },
    qihuan(val) {
      this.index = val;
      if (val == 0) {
        this.$route.meta.bread[2].name = "促销活动";
      } else {
        this.$route.meta.bread[2].name = "促销中的商品";
      }
      this.$forceUpdate();
    },
    change(page) {
      this.page = page;
      this.getList();
    },
    handleSearch(type) {
      this.page = 1;
      if (type == 2) {
        this.form = {
          name: "",
          status: 0,
          time: [],
          type: 0,
        };
      }
      this.getList();
    },
    toInfo(type, val) {
      if (val.promotions_type == 1) {
        sessionStorage.COUPONSTYPE = type;
        sessionStorage.COUPONSID = val.promotions_id;
        this.$router.options.routes.forEach((item) => {
          if (item.name == "marketingCenters") {
            item.children.forEach((val) => {
              if (val.name == "createCoupons") {
                val.meta.bread[3].name = "优惠券详情";
              }
            });
          }
        });
        this.$router.push("createCoupons");
      }
      if (val.promotions_type == 2) {
        sessionStorage.DISCOUNTTYPE = type;
        sessionStorage.DISCOUNTID = val.promotions_id;

        this.$router.options.routes.forEach((item) => {
          if (item.name == "marketingCenters") {
            item.children.forEach((val) => {
              if (val.name == "createDiscount") {
                val.meta.bread[3].name = "折扣活动详情";
              }
            });
          }
        });
        this.$router.push("discountInfo");
      }
      if (val.promotions_type == 3) {
        sessionStorage.SUITTYPE = type;
        sessionStorage.SUITID = val.promotions_id;
                this.$router.options.routes.forEach((item) => {
          if (item.name == "marketingCenters") {
            item.children.forEach((val) => {
              if (val.name == "createSuit") {
                val.meta.bread[3].name = "套装优惠详情";
              }
            });
          }
        });
        this.$router.push("suitInfo");
      }
      if (val.promotions_type == 4) {
        sessionStorage.LIMITTYPE = type;
        sessionStorage.LIMITID = val.promotions_id;
        this.$router.options.routes.forEach((item) => {
          if (item.name == "marketingCenters") {
            item.children.forEach((val) => {
              if (val.name == "createLimit") {
                val.meta.bread[3].name = "限时选购详情";
              }
            });
          }
        });
        this.$router.push("createLimit");
      }
    },
    toCope(type, val) {
      if (val.promotions_type == 1) {
        sessionStorage.COUPONSTYPE = type;
        sessionStorage.COUPONSID = val.promotions_id;
                this.$router.options.routes.forEach((item) => {
          if (item.name == "marketingCenters") {
            item.children.forEach((val) => {
              if (val.name == "createCoupons") {
                val.meta.bread[3].name = "创建优惠券";
              }
            });
          }
        });
        this.$router.push("createCoupons");
      }
      if (val.promotions_type == 2) {
        sessionStorage.DISCOUNTTYPE = type;
        sessionStorage.DISCOUNTID = val.promotions_id;
        
        this.$router.options.routes.forEach((item) => {
          if (item.name == "marketingCenters") {
            item.children.forEach((val) => {
              if (val.name == "createDiscount") {
                val.meta.bread[3].name = "建立新的折扣活动";
              }
            });
          }
        });
        this.$router.push("createDiscount");
      }
      if (val.promotions_type == 3) {
        sessionStorage.SUITTYPE = type;
        sessionStorage.SUITID = val.promotions_id;
                        this.$router.options.routes.forEach((item) => {
          if (item.name == "marketingCenters") {
            item.children.forEach((val) => {
              if (val.name == "createSuit") {
                val.meta.bread[3].name = "创建套装优惠";
              }
            });
          }
        });
        this.$router.push("createSuit");
      }
      if (val.promotions_type == 4) {
        sessionStorage.LIMITTYPE = type;
        sessionStorage.LIMITID = val.promotions_id;
                this.$router.options.routes.forEach((item) => {
          if (item.name == "marketingCenters") {
            item.children.forEach((val) => {
              if (val.name == "createLimit") {
                val.meta.bread[3].name = "创建新的店内限时选购";
              }
            });
          }
        });
        this.$router.push("createLimit");
      }
    },
    toEdit(type, val) {
      if (val.promotions_type == 1) {
        sessionStorage.COUPONSTYPE = type;
        sessionStorage.COUPONSID = val.promotions_id;
                        this.$router.options.routes.forEach((item) => {
          if (item.name == "marketingCenters") {
            item.children.forEach((val) => {
              if (val.name == "createCoupons") {
                val.meta.bread[3].name = "编辑优惠券";
              }
            });
          }
        });

        this.$router.push("createCoupons");
      }
      if (val.promotions_type == 2) {
        sessionStorage.DISCOUNTTYPE = type;
        sessionStorage.DISCOUNTID = val.promotions_id;
                this.$router.options.routes.forEach((item) => {
          if (item.name == "marketingCenters") {
            item.children.forEach((val) => {
              if (val.name == "createDiscount") {
                val.meta.bread[3].name = "编辑折扣活动";
              }
            });
          }
        });
        this.$router.push("createDiscount");
      }
      if (val.promotions_type == 3) {
        sessionStorage.SUITTYPE = type;
        sessionStorage.SUITID = val.promotions_id;
                                this.$router.options.routes.forEach((item) => {
          if (item.name == "marketingCenters") {
            item.children.forEach((val) => {
              if (val.name == "createSuit") {
                val.meta.bread[3].name = "编辑套装优惠";
              }
            });
          }
        });
        this.$router.push("createSuit");
      }
      if (val.promotions_type == 4) {
        sessionStorage.LIMITTYPE = type;
        sessionStorage.LIMITID = val.promotions_id;
                        this.$router.options.routes.forEach((item) => {
          if (item.name == "marketingCenters") {
            item.children.forEach((val) => {
              if (val.name == "createLimit") {
                val.meta.bread[3].name = "编辑店内限时选购";
              }
            });
          }
        });
        this.$router.push("createLimit");
      }
    },
    toData(type, val) {
      if (val.promotions_type == 1) {
        sessionStorage.COUPONSTYPE = type;
        sessionStorage.COUPONSID = val.promotions_id;
        this.$router.push("couponsOrder");
      }
      if (val.promotions_type == 2) {
        sessionStorage.DISCOUNTTYPE = type;
        sessionStorage.DISCOUNTID = val.promotions_id;
        this.$router.push("discountData");
      }
      if (val.promotions_type == 3) {
        sessionStorage.SUITTYPE = type;
        sessionStorage.SUITID = val.promotions_id;
        this.$router.push("suitOrder");
      }
      if (val.promotions_type == 4) {
        sessionStorage.LIMITTYPE = type;
        sessionStorage.LIMITID = val.promotions_id;
        this.$router.push("limitData");
      }
    },

    handleDel(data, type, index) {
      let text = type == 1 ? "结束" : "删除";
      let name =
        data.promotions_type == 1
          ? "优惠券"
          : data.promotions_type == 2
          ? "折扣活动"
          : data.promotions_type == 3
          ? "套装优惠"
          : data.promotions_type == 4
          ? "限时选购"
          : "";
      this.$confirm("确定" + text + "该" + name + "吗?", text + name, {
        confirmButtonText: text,
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        if (data.promotions_type == 1) {
          deleteCoupon({
            id: data.promotions_id,
            type: type,
          }).then((res) => {
            if (res.code == 1) {
              this.$message({
                type: "success",
                message: text + name + "成功!",
              });
              if (type == 1) {
                data.status_txt = "已过期";
                data.end_time = parseInt(Date.now() / 1000);
              } else {
                this.listData.list.splice(index, 1);
                this.total = this.total - 1;
              }
            }
          });
        }
        if (data.promotions_type == 2) {
          deleteDiscount({
            id: data.promotions_id,
            type: type,
          }).then((res) => {
            if (res.code == 1) {
              this.$message({
                type: "success",
                message: text + name + "成功!",
              });
              if (type == 1) {
                data.status_txt = "已过期";
                data.end_time = parseInt(Date.now() / 1000);
              } else {
                this.listData.list.splice(index, 1);
                this.total = this.total - 1;
              }
            }
          });
        }
        if (data.promotions_type == 3) {
          deleteSuit({
            id: data.promotions_id,
            type: type,
          }).then((res) => {
            if (res.code == 1) {
              this.$message({
                type: "success",
                message: text + name + "成功!",
              });
              if (type == 1) {
                data.status_txt = "已过期";
                data.end_time = parseInt(Date.now() / 1000);
              } else {
                this.listData.list.splice(index, 1);
                this.total = this.total - 1;
              }
            }
          });
        }
        if (data.promotions_type == 4) {
          deleteLimitPurchases({
            id: data.promotions_id,
            type: type,
          }).then((res) => {
            if (res.code == 1) {
              this.$message({
                type: "success",
                message: text + name + "成功!",
              });
              if (type == 1) {
                data.status_txt = "已过期";
                data.end_time = parseInt(Date.now() / 1000);
              } else {
                this.listData.list.splice(index, 1);
                this.total = this.total - 1;
              }
            }
          });
        }
      });
    },
  },
};
</script>
<style lang="less" >
.salesPromotion {
  padding-top: 20px;
  padding-bottom: 40px;
  .cell {
    width: 1400px;
    margin: 0 auto;
    background: #fff;
    padding: 10px 30px 20px;
    .tab-head {
      border-bottom: 1px solid #eee;
      display: flex;
      align-items: center;
      div {
        line-height: 60px;
        border-bottom: 3px solid #fff;
        margin-right: 20px;
        padding: 0 10px;
        cursor: pointer;
      }
    }
    .tab-content {
      .el-form-item__label {
        color: #b0b0b0;
      }
      .search {
        display: flex;
        align-items: center;
        font-size: 14px;
        color: #b0b0b0;
      }
    }
    .list {
      margin-top: 40px;
      border: 1px solid #eee;
      border-radius: 4px;
      .list-head {
        display: flex;
        align-items: center;
        background: #f6f6f6;
        padding: 10px 20px;
        font-size: 14px;
        justify-content: space-between;
        color: #858585;
      }
      .list-content {
        display: flex;
        padding: 20px;
        align-items: center;
        font-size: 12px;
        justify-content: space-between;
        .tag1 {
          background: #eaf9ef;
          color: #55cc77;
          display: initial;
          padding: 2px 4px;
          border-radius: 2px;
        }
        .tag {
          background: #fff1f0;
          color: #ee4d2d;
          display: initial;
          padding: 2px 4px;
          border-radius: 2px;
        }
        .tag2 {
          background: #eeeeee;
          color: #666;
          display: initial;
          padding: 2px 4px;
          border-radius: 2px;
        }
      }
    }
  }
}
</style>